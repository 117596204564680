import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter,Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Footer from '../components/Footer2';
import MainNav from '../components/MainNav';
import ModalComp from '../components/DialogProduct';

import './product.css'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 200,
  },
  formControl2: {
    minWidth: 120,
  },
  selectEmpty: {
    //marginTop: theme.spacing.unit * 2,
    marginTop: theme.spacing(2),
  },
});

class ProductContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requiredItem: 0,
      isLoading: true,
      isLoadingData: false,
      isDone: false,
      modalItem: [],
      showData:false,
      products: [],
      brands:[],
      setAge:'',
      searchItem:'',
      catItem:[],
      catItemAll:[],
      items:{},
      searchItem:'',
      searchField:'',
      brandName:'',
      brandDes:'',
      search:null,
      category:'CATU',
      brandFilter:null,
      catFilter:null,
      show: false,
      color: 'rgba(52, 52, 52, 0.8)',
      labelWidth: 0,
      content:{},name:'PT Cipta Tapak Sejahtera ', parent:'- Product ', subParent:'',
      contentMeta:'Product of PT Cipta Tapak Sejahtera'
    };
  }
  handleChange = event => {
    this.setState({
      setAge:event.target.value
    });
  };
  handleClose = () => {
    this.setState({
      setOpen:false
    });
  };
  handleOpen = () => {
    this.setState({
      setOpen:true
    });
  }

  openModal = product =>(product) => {
    this.setState({
      show: true,
      nameItem:product
    });
  }

  openModalWithItem(index) {
		this.setState({
      show: true,
			requiredItem: index
		});
	  }
  setFirstName = (e) => {
     this.setState({
      searchField: e.target.value.trim().toLowerCase()
     })
  }
  closeModal=nr=>()=> {
    if(this.state.searchField!=""){
      const names="/product?brand="+this.state.brandName+"&search="+this.state.searchField;
      window.history.replaceState("","",names)
      }else{
        const names="/product?brand="+this.state.brandName;
      window.history.replaceState("","",names)
      }
    this.setState({
        show : false
    });
  }
  handleClick(event){
    if(this.state.searchField!=""){
    const names="/product?brand="+this.state.brandName+"&search="+this.state.searchField;
    window.history.replaceState("","",names)
    }else{
      const names="/product?brand="+this.state.brandName;
    window.history.replaceState("","",names)
    }
    this.setState({
      products:[],
      isLoadingData:true
    });
    this.loadItems();

    }
    handleClickBrand(event){
      this.setState({
        products:[],
        isLoading:true
      });
      this.loadItem(event);
  
      }
  handleChange = e => {
      this.setState({ searchItem: e.target.value.trim().toLowerCase() });
  }
  handleBrand(id,name,des,seo){
    var datas=this.state.brands
    this.setState({
      subParent:"- "+name,
      contentMeta:name+" - "+des+" - "+seo,
      catItem:[],
      catItemAll:[],
      brandName:name,
    })
    this.loadBrandName(name)
    for(var i=0;i<datas.length;i++){
      var ids=datas[i].id;
      //console.log("ada")
      if(ids==id){
        
        this.setState({
          catItem:datas[i].cats
        })
        
        break
      }
    }
    
  }
  something=(event)=> {
    if (event.key === "Enter") {
        this.handleClick(event)
    }
  }
  changedRole = ev => {
    var datas=this.state.brands
    this.setState({ 
      brandFilter: ev.target.value,
     })
    if(ev.target.value==0){
      this.setState({
        catItem:this.state.catItemAll
      })
      return;
    }
    for(var i=0;i<datas.length;i++){
      var id=datas[i].id;
      if(id===ev.target.value){
        
        this.setState({
          catItem:datas[i].cats
        })
        break
      }
    }
  }
  changedRoles(id) {
    var datas=this.state.brands
    this.setState({ 
      brandFilter: id,
     })
    if(id==0){
      this.setState({
        catItem:this.state.catItemAll
      })
      return;
    }
    for(var i=0;i<datas.length;i++){
      var ids=datas[i].id;
      if(ids===id){
        
        this.setState({
          catItem:datas[i].cats
        })
        break
      }
    }
  }
changedCat = ev => {
  this.setState({ catFilter: ev.target.value })
}
  searchSpace=(event)=>{
    let keyword = event.target.value;
    this.setState({search:keyword})
  }
  listenScrollEvent = e => {
		if (window.scrollY > 400) {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		} else {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		}
    }
componentDidMount(){
  ////console.log(this.props) 
  let brand = (new URLSearchParams(window.location.search)).get("brand")
  let search = (new URLSearchParams(window.location.search)).get("search")
  console.log(brand)
  console.log(search)
  const {params} =this.props.match;
  
  this.setState({category:'catu'})
  window.addEventListener('scroll', this.listenScrollEvent)


  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token"});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$productIndex, requestOptions)
  .then(response => response.json())
  .then(result => {
    //console.log(result.result)
  })
  .catch(error => console.log('error', error));
  
  this.loadContent()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"t":"token"});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(window.$brandIndex, requestOptions)
      .then(response => response.json())
      .then(responseJson => {
        //console.log(responseJson.result)
      this.setState({
        brands:responseJson.result,
        isLoading:false
      });
      })
      .catch(error => console.log('error', error));
      const url = this.props.location.pathname.split('/')
  if(brand!=null){
    const textUp=brand.toUpperCase();
    this.loadBrandName(textUp)
    this.setState({
      subParent:"- "+brand,
      brandName:brand,
    })
    if(search!=null){
      this.setState({
        searchField:search,
        searchItem:search
      })
    }

  }
  else{
    this.loadcat();
  }
}
loadBrandId(ids){
  const text=parseInt(ids)
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"t":"token","id":text});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(window.$brandFindById, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          contentMeta:result.result[0].description,
          catItem:result.result[0].cats,
          catItemAll:result.result[0].cats
        })
        })
      .catch(error => console.log('error', error));
}
loadBrandName(name){
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"t":"token","name":name});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(window.$brandFindByName, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          showData:true,
          brandFilter:result.result[0].id,
          contentMeta:name+" - "+result.result[0].description+" - "+result.result[0].seo,
          catItem:result.result[0].cats,
          catItemAll:result.result[0].cats,
          brandDes:result.result[0].description
        })
        this.handleClickBrand(result.result[0].id);
        })
      .catch(error => console.log('error', error));
}
loadContent(){
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = "";

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(window.$contentIndexCompact, requestOptions)
    .then(response => response.json())
    .then(result => {
    this.setState({
      content:result.result
    })
    })
    .catch(error => console.log('error', error));
  }
loadItem(id){
  const{products}=this.state;
  
  let keyword=parseInt(id)
  let keyword2=this.state.searchField
  let keyword3=this.state.catFilter
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({"t":"token","bid":keyword,"bcat":keyword3,"q":keyword2});
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch(window.$productFind, requestOptions)
  .then(response => response.json())
  .then(responseJson => {
    //console.log(responseJson.result)
    
  this.setState({
    products:responseJson.result,
    isLoading:false
  });
  })
    .catch(error => console.log('error', error));
}
loadItems(){
  const{products}=this.state;
  
  let keyword=parseInt(this.state.brandFilter)
  let keyword2=this.state.searchField
  let keyword3=this.state.catFilter
  console.log(keyword)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({"t":"token","bid":keyword,"bcat":keyword3,"q":keyword2});
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch(window.$productFind, requestOptions)
  .then(response => response.json())
  .then(responseJson => {
    //console.log(responseJson.result)
    
  this.setState({
    products:responseJson.result,
    isLoadingData:false
  });
  })
    .catch(error => console.log('error', error));
}

loadcat(){
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({"t":"token"});
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch(window.$productListBrandCat, requestOptions)
    .then(response => response.json())
    .then(result => {
      //console.log(result.result)
      var cats={};
      for(var i=0;i<result.result.lenght;i++){
        cats[result.result[i]]=result.result[i]
      }
      this.setState({
       catItemAll:result.result,
       catItem:result.result
      }) 
    })
    .catch(error => console.log('error', error));
}

handleModal(id){
  
  this.setState({
    show: true,
    isDone:false
   
  });
  this.loadModal(id);
}
loadModal(id){
  const text=parseInt(id)
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token","id":text});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$productFindById, requestOptions)
  .then(response => response.json())
  .then(result => {
    this.setState({
      modalItem:result.result,
      isDone:true
     
    })
    })
  .catch(error => console.log('error', error));
}
handleBack(){
  this.setState({
    subParent:'',
    contentMeta:'Product of PT Cipta Tapak Sejahtera',
    showData:false
  })
}
findByName(names){
  if(names!=null){
    return names.value
  }
   return ""
  
   }
   findByUrl(url){
  if(url!=null){
    var urls=url.img_url.split('/')
      var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+"L"+"/"+urls[3]
    return img
  }
   return require('../assets/placeholder.jpg')
  
   }
loadImage(images){
  if(images[0]!=null){
    var urls=images[0].url.split('/')
    var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+urls[3]+"/"+"M"+"/"+urls[4]
    return img
  }
  return require("../assets/placeholder.jpg");
}
	render() {
    
    const { showData,isLoading,isLoadingData, catItem, brandName, searchField, products,brands,modalItem,isDone,content,name,parent,subParent } = this.state;
    const { classes } = this.props;
    let product=products;
    
		return(
<div>
<Helmet>
<title>{name+parent+subParent}</title>
<meta name="description" content={this.state.contentMeta} />
</Helmet>
<Backdrop open={isLoading}>
<CircularProgress color="inherit" />
</Backdrop>
<div>
 
<MainNav route='product'/>

      <div className="mb-5 " style={{marginTop:100,paddingBottom:30}}>
      <div className="container" style={{marginTop:40}}>
        <div className="">
          <div>
            <div className="section-header wow fadeInUp">
              <h3 style={{color:'#000'}}>{this.findByName(content.PRODUCT_TITLE)}</h3>
            </div>
            {isLoading ? (
              <div></div>
            ) : (<div>
            {showData?(<div>
            <div className="row section-header wow fadeInUp form-group" style={{right:0}}>
            <FormControl className="mb-3 ml-4 pr-2 form-group" style={{minWidth:120}}>
            <Link to={`/product`}>
              <Button variant="contained" style={{height:55}} color="primary"  className="btn"  onClick={()=>this.handleBack()}>BRAND</Button></Link>
              </FormControl>
            <div className="mb-3 ml-3 pr-2">
            <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={ref => {this.InputLabelRef = ref;}} htmlFor="category-select-form">Category</InputLabel>
              <Select
                native
                labelId="demo-controlled-open-select-label"
                open={this.state.setOpen}
                //onClose={this.handleClose}
                //onOpen={this.handleOpen}
                value={this.state.setAge}
                onChange={this.changedCat}
                input={
                  <OutlinedInput
                    name="Category"
                    labelWidth={65}
                    id="category-select-form"
                  />
                }
              >

              <option value={" "} >All Category</option>
              {catItem.map(cat => (
                  <option key={cat} value={cat}>{cat}</option>
              ))}
              {
              //   <MenuItem value="">
              //     <em>All</em>
              //   </MenuItem>
              //   {catItem.map(cat => (
              //     <MenuItem value={cat}>{cat}</MenuItem>
              // ))}
               } 
              </Select>
            </FormControl>
            </div>
              <FormControl className="mb-3 ml-3 pr-2 form-group" style={{minWidth:120}}>
                  <TextField
                      id="outlined-helperText"
                      label="Search"
                      defaultValue={this.state.searchItem}
                      helperText="Type For Search in name or description"
                      variant="outlined"
                      onChange = {e => this.setFirstName(e)}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          this.handleClick(event);
                          if(searchField!=""){
                          const names="/product?brand="+brandName+"&search="+searchField;
                          window.history.replaceState("","",names)
                          }else{
                            const names="/product?brand="+brandName;
                          window.history.replaceState("","",names)
                          }
                        }
                      }}
                    />
              </FormControl>
              <FormControl className="mb-3 ml-3 pr-2 form-group" style={{minWidth:120}}>
                    <Button variant="outlined" size="large"  onClick={(event) => this.handleClick(event)}>Search</Button>
              </FormControl>

            </div>

            <div className="container " style={{marginBottom:50}}>
            {isLoadingData ? (
              <div></div>
            ) : ( <div className="product-grid row">
            {product.map((info)=>{
              let tit= info.name
              tit = tit.replace(' ','-')
              return(
            <div key={info.code} className="col-sm-3">
            <Paper elevation={3} >
              <div className="card wow fadeInUp mb-4" style={{height:270,backgroundColor:'white'}} >
                <div className="mt-2">
                    {this.loadImage(info.images) ? (<img title={info.brand.name+" "+info.name} className="img-fluid "src={this.loadImage(info.images)} style={{height:80}} alt={info.brand.name+" "+info.name} onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+info.images[0].url}/>) : (<div><img className="img-fluid "src={"../assets/placeholder.jpg"} style={{height:80}}/></div>) }
                </div>
                <hr/>
                <div className="mt-2 ml-1 mr-1" style={{textAlign:'left',flexGrow:1}}>
                    <p style={{color:'grey'}}>{info.brand.name}</p>
                    <Link to={`/product?brand=${info.brand.name}&code=${info.id}`} className="text-primary" onClick={() => this.handleModal(info.id)}>
                        {info.name}
                      </Link>
                      <br />
                      
                </div>
                <div className="d-flex align-items-baseline mb-2 ml-2">
                <Link to={`/product?brand=${info.brand.name}&code=${info.id}`} className="text-primary">
                      <Chip
                        variant="outlined"
                        size="small"
                        avatar={<Avatar>p</Avatar>}
                        label={info.code}
                        color="primary"
                        onClick={() => this.handleModal(info.id)}
                      /></Link>
                      </div>
              </div>

            </Paper>
            </div>
            )})}
          </div>)
            }
                
            </div>
            </div>):(
              <div className="d-flex justify-content-center">
              <div className="row ">
              {brands.map((item)=>(
                <div key={item.id} className="col-md-6 mb-3">
                
            <div className=" d-flex justify-content-center" style={{flexDirection:'row'}}>
            <Paper elevation={3} >
            <div className="card wow fadeInUp pl-3 pr-3 pt-3" style={{ width:300,height:300,backgroundColor:'white'}} >
            <div className="mt-2">
            <Link to={`/product?brand=${item.name}`} onClick={()=>this.handleBrand(item.id,item.name,item.description,item.seo)}><img title={item.name} style={{height:150, maxWidth:300}} src={this.loadImage(item.images)} alt={item.name} onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+item.images[0].url}/></Link>
            </div>
            <hr/>
            <div className="mt-2 ml-1 mr-1" style={{textAlign:'left',flexGrow:1}}>
                        <p style={{color:'grey'}}>{item.description}</p>
                          <br />
                          
            </div>
            </div>
            </Paper>
						</div>
						
                </div>
              ))}
              </div>
              </div>
            )}
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <Footer/>

    { modalItem.map((info)=>(
      <ModalComp
      show={this.state.show}
      close={this.closeModal()}
      name={info.name}
      image={this.loadImage(info.images)}
      images={info.images}
      brand={info.brand.name}
      category={info.category}
      code={info.code}
      desc={info.description}
      done={isDone}
      />
    ))}
  
  
</div>
</div>
		)
	}
}

ProductContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(ProductContainer)) ;